import { useCallback } from 'react';
import { SVGDuplicate } from '_Icons';
import { useAnimatingState } from '_Hooks';
import { copyToClipboard } from '../../lib/Utils';

import './CopyToClipboard.scss';

const CopyToClipboard = (props) => {
  const {
    title,
    style,
    text,
    children,
    hideInput = false
  } = props;

  const [hasBeenCopied, setHasBeenCopied] = useAnimatingState(false, 3000);

  const onIconClick = useCallback(
    () => {
      let successful = copyToClipboard(text);
      setHasBeenCopied(successful);
    },
    [text, setHasBeenCopied]
  );

  return (
    <span
      className={'copyable-text-to-clipboard'}
      style={style}
      data-component={'copy_to_clipboard'}
    >
      {children && (
        <span className={'copyable-text-to-clipboard__label'} >
          {children}
        </span>
      )}
      <span
        className={'copyable-text-to-clipboard__text-wrapper'}
        data-component={'copy_to_clipboard_text'}
        style={hideInput ? { padding: '8px' } : {}}
      >
        {title && (
          <span className={'copyable-text-to-clipboard__title'} >
            {title}
          </span>
        )}
        <span className={'copyable-text-to-clipboard__text-group'}>
          <input
            className={'copyable-text-to-clipboard__text'}
            value={hasBeenCopied ? 'Copied' : hideInput ? '' : text}
            style={hideInput ? hasBeenCopied ? { width: '60px' } : { width: '0px' } : {}}
            readOnly={true}
          />
          <span
            className={'copyable-text-to-clipboard__icon'}
            onClick={onIconClick}
            style={hideInput ? { marginLeft: '0px' } : {}}
          >
            <SVGDuplicate size={16} />
          </span>
        </span>
        {title && (
          <span style={{ visibility: 'hidden' }} />
        )}
      </span>
    </span>
  );
};

export default CopyToClipboard;
